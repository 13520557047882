import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import Auth from "@/views/Auth.vue";
import Admin from "@/views/Admin.vue";
import Collective from "@/views/Collective.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/auth",
    name: "auth",
    component: Auth,
  },
  {
    path: "/admin",
    name: "admin",
    component: Admin,
  },
  {
    path: "/oc/:userId/:collectiveId",
    name: "collective",
    component: Collective,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
