<template>
  <div class="home">
    <top-bar :user="user" @add-clicked="addCollective" />
    <header-title />
    <div class="collectives">
      <collective-small
        v-for="collective in collectives"
        :key="collective.id"
        :collective="collective"
      />
    </div>
    <div class="dialog" v-if="showDialog">
      <collective-add-edit
        :user="user"
        @collective-did-create="collectiveDidCreate"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.collectives {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.dialog {
  position: absolute;
  z-index: 10;
  left: 20px;
  top: 20px;
  width: 200px;
  height: 200px;
  background-color: white;
  border: 1px solid #eee;
}
</style>

<script>
import CollectiveSmall from "@/components/CollectiveSmall.vue";
import CollectiveAddEdit from "@/components/CollectiveAddEdit.vue";
import TopBar from "@/components/TopBar";
import HeaderTitle from "@/components/HeaderTitle";

export default {
  components: {
    CollectiveSmall,
    CollectiveAddEdit,
    HeaderTitle,
    TopBar,
  },
  data() {
    return {
      user: null,
      defaultUserId: "N0FqmmuQ1WZ5nYfA4kRKSYWxtni2",
      collectives: [],
      showAddDialog: false,
    };
  },
  computed: {
    showDialog() {
      return this.showAddDialog;
    },
  },
  mounted() {
    this.$firebase
      .auth()
      .setPersistence(this.$firebase.auth.Auth.Persistence.LOCAL);
    this.$firebase.auth().onAuthStateChanged((user) => {
      // https://firebase.google.com/docs/reference/js/firebase.User
      this.user = user;
      this.loadData();
      console.log("Signed in user:", this.user);
    });
  },
  methods: {
    loadData() {
      const db = this.$firebase.firestore();
      const userId = this.defaultUserId;

      const getCollects = (collectsRef) => {
        const collects = [];
        collectsRef.forEach((collectDocSnapshot) => {
          collects.push(collectDocSnapshot.data());
        });
        return collects;
      };

      db.collection("users")
        .doc(userId)
        .collection("collectives")
        .get()
        .then((collectivesRef) => {
          const collectives = [];
          collectivesRef.forEach(async (collectiveDocSnapshot) => {
            let collective = collectiveDocSnapshot.data();
            collective.userId = userId;
            collective.id = collectiveDocSnapshot.id;
            collective.collects = await collectiveDocSnapshot.ref
              .collection("collects")
              .get()
              .then(getCollects);
            collectives.push(collective);
          });
          this.collectives = collectives;
        });
    },
    addCollective() {
      this.showAddDialog = true;
    },
    collectiveDidCreate(collective) {
      this.showAddDialog = false;
      console.log(collective);
    },
  },
};
</script>
