<template>
  <div class="collective" @drop.prevent="dropFile" @dragover.prevent>
    <top-bar :user="signedInUser" />
    <header-title />
    <h2>{{ collective.name }}</h2>
    <div class="collects">
      <div
        class="content"
        v-for="collect in collective.collects"
        :key="collect.id"
      >
        <img :src="collect.url" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$image-width: 200px;
$stroke-color: #e0e0e0;

.collective {
  margin: 40px;
}

.collects {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .content {
    background-color: $stroke-color;
    margin: 10px;

    img {
      width: $image-width;
      height: $image-width;
    }
  }
}
</style>

<script>
import HeaderTitle from "@/components/HeaderTitle";
import TopBar from "@/components/TopBar";
import { v4 as uuidv4 } from "uuid";

export default {
  components: {
    HeaderTitle,
    TopBar,
  },
  data() {
    return {
      signedInUser: null,
      userId: "",
      collectiveId: "",
      collective: {},
      uploadFilesQueue: [],
    };
  },
  mounted() {
    this.userId = this.$route.params.userId;
    this.collectiveId = this.$route.params.collectiveId;
    this.getCollective();

    this.$firebase
      .auth()
      .setPersistence(this.$firebase.auth.Auth.Persistence.LOCAL);
    this.$firebase.auth().onAuthStateChanged((user) => {
      // https://firebase.google.com/docs/reference/js/firebase.User
      this.signedInUser = user;
    });
  },
  methods: {
    getCollective() {
      let db = this.$firebase.firestore();

      const getCollects = (collectsRef) => {
        const collects = [];
        collectsRef.forEach((collectDocSnapshot) => {
          collects.push(collectDocSnapshot.data());
        });
        return collects;
      };

      db.collection("users")
        .doc(this.userId)
        .collection("collectives")
        .doc(this.collectiveId)
        .get()
        .then(async (collectiveDocSnapshot) => {
          let collective = collectiveDocSnapshot.data();
          collective.collects = await collectiveDocSnapshot.ref
            .collection("collects")
            .get()
            .then(getCollects);
          this.collective = collective;
        });
    },
    isOwner() {
      if (this.signedInUser && this.signedInUser.uid == this.userId) {
        return true;
      }
      return false;
    },
    dropFile(e) {
      if (!this.isOwner()) {
        console.log('Not owner of this collective.')
        return;
      }
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) {
        console.log('No files')
        return;
      }
      [...droppedFiles].forEach((f) => {
        this.uploadFilesQueue.push(f);
      });
      this.startUpload();
    },
    startUpload() {
      const storage = this.$firebase.storage();
      const db = this.$firebase.firestore();

      this.uploadFilesQueue.forEach((f) => {
        const fileId = uuidv4();
        const fileComponents = f.name.split('.')
        const fileExtension = fileComponents[fileComponents.length - 1]
        const path = `users/${this.signedInUser.uid}/collectives/${this.collectiveId}/${fileId}.${fileExtension}`;
        const ref = storage.ref(path);
        console.log('Uploading', path);
        ref.put(f).then(async (snapshot) => {
          const info = {
            id: fileId,
            storagePath: path,
            url: await snapshot.ref.getDownloadURL(),
          };
          console.log(info)
          db.collection("users")
            .doc(this.signedInUser.uid)
            .collection("collectives")
            .doc(this.collectiveId)
            .collection("collects")
            .doc(fileId)
            .set(info)
            .then(() => {
              console.log("Added collect");
            });
        });
      });
    },
  },
};
</script>
