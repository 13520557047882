<template>
  <div class="collective-small">
    <h2>
      <router-link :to="url">{{ collective.name }}</router-link>
    </h2>
    <h3>{{ collective.count }}</h3>
    <div class="collection">
      <div
        class="content"
        v-for="collect in collective.collects"
        :key="collect.id"
      >
        <img :src="collect.url" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$stroke-color: #e0e0e0;
$content-width: 80px;
$content-margin: 1px;
$collective-padding: 10px;
$collective-margin: 10px;
$text-color: #666;

h2 {
  font-size: 32px;

  a {
    text-decoration: none;
    color: $text-color;
  }

}

.collective-small {
  width: $content-width * 4 + $content-margin * 4 + $content-margin +
    $collective-padding * 2 + $collective-margin;
  border: 1px solid $stroke-color;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
}

.collection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .content {
    width: $content-width;
    height: $content-width;
    background-color: $stroke-color;
    margin: $content-margin;

    img {
      width: $content-width;
      height: $content-width;
    }
  }
}
</style>

<script>
export default {
  props: {
    collective: Object,
  },
  computed: {
    url() {
      return `/oc/${this.collective.userId}/${this.collective.id}/`;
    },
  },
};
</script>
