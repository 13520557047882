<template>
  <div class="auth">
    <h1>Sign in and out</h1>
    <div class="signout" v-show="isSignedIn">
      <a href="#" @click.prevent="signOut">Sign out</a>
    </div>
    <div class="signin" v-show="!isSignedIn">
      <input type="email" class="email" name="email" placeholder="email" />
      <input type="password" class="password" name="password" />
      <a href="#" class="signinButton" v-on:click="signIn">Sign In</a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.email,
.password {
  width: 200px;
  padding: 0.2rem 0.5rem;
  margin: 0.2rem 0.5rem;
}
.signinButton {
  background-color: #999;
  text-decoration: none;
  color: black;
  margin: 0.2rem 0.5rem;
  padding: 0.4rem 0.5rem;
  border-radius: 1rem;
  width: 100px;
  text-align: center;
}
</style>

<script>
export default {
  data() {
    return {
      user: null,
    };
  },
  computed: {
    isSignedIn() {
      return this.user;
    },
  },
  mounted() {
    this.$firebase
      .auth()
      .setPersistence(this.$firebase.auth.Auth.Persistence.LOCAL);
    this.$firebase.auth().onAuthStateChanged((user) => {
      // https://firebase.google.com/docs/reference/js/firebase.User
      this.user = user;
      console.log("Signed in user:", this.user);
    });
  },
  methods: {
    signIn() {
      let email = document.querySelector(".email").value;
      let password = document.querySelector(".password").value;
      this.$firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((userCredential) => {
          console.log("signed in", userCredential);
          this.user = userCredential.user;
          this.$router.push({ name: "home" });
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log(errorCode, errorMessage);
        });
    },
    signOut() {
      this.$firebase.auth().signOut();
    },
  },
};
</script>
