<template>
  <div class="collective-add-edit">
    <div class="collective-id"><input type="text" v-model="id" /></div>
    <div class="collective-name"><input type="text" v-model="name" /></div>
    <div class="buttons">
      <button class="delete" @click.prevent="deleteCollective">Delete</button>
      <button class="edit" @click.prevent="editCollective">Edit</button>
      <button class="create" @click.prevent="createCollective">Create</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default() {
        return {};
      },
    },
    collective: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      id: "",
      name: "",
    };
  },
  computed: {
    canEdit() {
      return this.collective.id;
    },
    canDelete() {
      return this.collective.id;
    },
    canCreate() {
      return !this.collective.id;
    },
  },
  methods: {
    createCollective() {
      const db = this.$firebase.firestore();
      const collective = { id: this.id, name: this.name };
      db.collection("users")
        .doc(this.user.uid)
        .collection("collectives")
        .doc(this.id)
        .set(collective)
        .then(() => {
          this.$emit("collective-did-create", collective);
        });
    },
    editCollective() {
      const db = this.$firebase.firestore();
      const collective = { id: this.id, name: this.name };
      db.collection("users")
        .doc(this.user.uid)
        .collection("collectives")
        .doc(this.id)
        .set(collective, { merge: true })
        .then(() => {
          this.$emit("collective-did-edit", collective);
        });
    },
    deleteCollective() {
      // TODO: need to recursively delete the collects too.
      const db = this.$firebase.firestore();
      const collective = { id: this.id, name: this.name };
      db.collection("users")
        .doc(this.user.uid)
        .collection("collectives")
        .doc(this.id)
        .delete()
        .then(() => {
          this.$emit("collective-did-delete", collective);
        });
    },
  },
};
</script>
