<template>
  <div class="admin">
    <div class="controls" v-if="isAdmin">
      <a class="control" href="#" @click.prevent="getAllCollectives"
        >getAllCollectives</a
      >
    </div>
    <div class="collectives">
      <div
        class="collective"
        v-for="collective in collectives"
        :key="collective.id"
      >
        <div class="collective-user">{{ collective.user }}</div>
        <div class="collective-id">{{ collective.id }}</div>
        <div class="collective-name">{{ collective.name }}</div>
        <div class="collective-count">{{ collective.collects.length }}</div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.admin {
  padding: 20px;
}

.controls {
  display: flex;
  flex-direction: column;
}

.collectives {
  margin: 20px;
  display: flex;
  flex-direction: column;
}

.collective {
  display: flex;
  flex-direction: row;
  .collective-user {
    width: 400px;
  }
  .collective-id {
    width: 200px;
  }
  .collective-name {
    width: 200px;
  }
}
</style>

<script>
import { isAdmin } from "../admin";

const liquidxUser = "N0FqmmuQ1WZ5nYfA4kRKSYWxtni2";

export default {
  data() {
    return {
      isAdmin: false,
      collectives: [],
    };
  },
  mounted() {
    this.$firebase
      .auth()
      .setPersistence(this.$firebase.auth.Auth.Persistence.LOCAL);
    this.$firebase.auth().onAuthStateChanged((user) => {
      this.isAdmin = isAdmin(user);
      this.getAllCollectives();
    });
  },
  methods: {
    async getAllCollectives() {
      this.collectives = await this.getUserCollectives(liquidxUser);
    },
    getUserCollectives(userId) {
      const db = this.$firebase.firestore();
      return db
        .collection("users")
        .doc(userId)
        .collection("collectives")
        .get()
        .then((collectiveRefs) => {
          let collectives = [];
          collectiveRefs.forEach(async (collectiveDocSnapshot) => {
            const collectiveId = collectiveDocSnapshot.id;
            const contents = await this.getCollectiveContents(
              collectiveDocSnapshot.ref
            );
            const collective = {
              id: collectiveId,
              user: userId,
              name: collectiveDocSnapshot.data().name,
              collects: contents,
            };
            console.log(collective);
            collectives.push(collective);
          });
          return collectives;
        });
    },
    getCollectiveContents(collectiveRef) {
      return collectiveRef
        .collection("collects")
        .get()
        .then((collectsRefs) => {
          const collects = [];
          collectsRefs.forEach((collectDocSnapshot) => {
            collects.push(collectDocSnapshot.data());
          });
          console.log(collects);
          return collects;
        });
    },
    mediaUrlPromise(storage, userId, collectionId, mediaId) {
      const path = `users/${userId}/collectives/${collectionId}/${mediaId}`;
      const ref = storage.ref(path);
      return ref.getDownloadURL();
    },
  },
};
</script>
