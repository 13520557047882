<template>
  <div class="top-bar">
    <div class="sign-in-button" v-if="!user">
      <span class="action-item"><router-link to="/auth">Sign In</router-link></span>
    </div>
    <div class="account" v-if="user">
      <span class="action-item"><router-link to="/auth">{{ user.email }}</router-link></span>
      <span class="action-item"><a href="#" @click.prevent="addCollective">Add Collective</a></span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.header {
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.action-item {
  margin-right: 10px;
}
</style>

<script>
export default {
  props: {
    user: Object,
  },
  methods: {
    addCollective() {
      this.$emit("add-clicked");
    },
  },
};
</script>
