import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import firebase from "firebase/app";
require("firebase/auth");
require("firebase/firestore");
require("firebase/storage");

Vue.config.productionTip = false;

var firebaseConfig = {
  apiKey: "AIzaSyB3h2HBFIlbdPzGZ6XK-JSzTZzyL4CZVoE",
  authDomain: "obsessivecollective.firebaseapp.com",
  projectId: "obsessivecollective",
  storageBucket: "obsessivecollective.appspot.com",
  messagingSenderId: "483468696744",
  appId: "1:483468696744:web:40cb5ad399e71686ea369d",
  measurementId: "G-SR8Y60Q35M",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
Vue.prototype.$firebase = firebase;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
